import React from "react";
import RegistrationModel from "./RegistrationModel";
import MailingListModel from "./MailingListModel";

function Main() {
  const apiUrl = window.location.href.includes("localhost")
    ? "http://localhost:3001"
    : "https://account.calibrite.com";

  // Check if the URL contains 'reset-password'
  if (window.location.href.includes("reset-password")) {
    return (
      <RegistrationModel
        isOpen={true}
        onClose={() => {}}
        apiUrl={apiUrl}
        openSoftwareLicense={() => {}}
        softwareLicenseVersion={"web-demo"}
      />
    );
  }

  // For https://signup.calibrite.com we want all links to open to the marketing page
  return (
    <MailingListModel isOpen={true} onClose={() => {}} apiUrl={apiUrl} />
  );

  /* 
  // For development we can test the registration page without embedding within the software
  if (window.location.href.includes("mailing")) {
    return (
      <MailingListModel isOpen={true} onClose={() => {}} apiUrl={apiUrl} />
    );
  } else {
    return (
      <RegistrationModel
        isOpen={true}
        onClose={() => {}}
        apiUrl={apiUrl}
        openSoftwareLicense={() => {}}
        softwareLicenseVersion={"web-demo"}
      />
    );
  }
  */
}

export default Main;
