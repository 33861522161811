import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ModalFooter,
  Text,
  Flex,
  Input,
  Select,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useSignup from "./useSignup";
import { countries, softwareLanguages } from "./constants/data";
import { openLink } from "./RegistrationModel";

const getCountryFromLocale = () => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const country = locale.split("-")[1]; // The part after the dash is usually the country code
  return country || "Country not found";
};

// Privacy policy URLs for different languages
const getPrivacyPolicyUrl = (lang) => {
  const urls = {
    en: "https://calibrite.com/privacy-policy/",
    es: "https://calibrite.com/es/privacy-policy/?noredirect=es-ES",
    fr: "https://calibrite.com/fr/privacy-policy/?noredirect=fr-FR",
    de: "https://calibrite.com/de/privacy-policy/?noredirect=de-DE",
    it: "https://calibrite.com/it/privacy-policy/?noredirect=it-IT",
    pl: "https://calibrite.com/pl/privacy-policy/?noredirect=pl-PL",
    ja: "https://calibrite.com/jp/privacy-policy/?noredirect=ja-JP",
  };
  return urls[lang] || urls.en; // Default to English if language not found
};

// Terms of Use URLs for different languages
const getTermsOfUseUrl = (lang) => {
  const urls = {
    en: "https://calibrite.com/terms-of-use/",
    es: "https://calibrite.com/es/terms-of-use/?noredirect=es-ES",
    fr: "https://calibrite.com/fr/terms-of-use/?noredirect=fr-FR",
    de: "https://calibrite.com/de/terms-of-use/?noredirect=de-DE",
    it: "https://calibrite.com/it/terms-of-use/?noredirect=it-IT",
    pl: "https://calibrite.com/pl/terms-of-use/?noredirect=pl-PL",
    ja: "https://calibrite.com/jp/terms-of-use/?noredirect=ja-JP",
  };
  return urls[lang] || urls.en; // Default to English if language not found
};

export const DetailsStage = ({
  onSubmit,
  email,
  password,
  onBack,
  apiUrl,
  newsletter,
  openSoftwareLicense,
  softwareLicenseVersion,
}) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contactConsent: true,
      country: getCountryFromLocale(),
    },
  });
  const { signup, loading, error } = useSignup(apiUrl, softwareLicenseVersion);

  const isMarketingSignup =
    localStorage.getItem("isMarketingSignup") === "true";

  const onSubmitForm = useCallback(
    async (data) => {
      const fullData = {
        ...data,
        email,
        password,
      };

      localStorage.setItem("emailOptIn", data.contactConsent);

      const response = await signup(fullData, isMarketingSignup, newsletter);
      if (response.user) {
        console.log("User created successfully");
        onSubmit(password);
      }
    },
    [signup, email, password, isMarketingSignup, onSubmit, newsletter]
  );

  // Get the current language for URLs
  const currentLang = i18n.language.split('-')[0];
  const privacyPolicyUrl = getPrivacyPolicyUrl(currentLang);
  const termsOfUseUrl = getTermsOfUseUrl(currentLang);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Flex direction="column" gap={4}>
        {!isMarketingSignup && (
          <>
            <Flex direction="row" gap={4}>
              <Box flex={1}>
                <Input
                  autoFocus
                  placeholder={t("label.First Name")}
                  {...register("firstName", { required: true })}
                />
                {errors.firstName && (
                  <Text color="red.500">{t("label.requiredField")}</Text>
                )}
              </Box>
              <Box flex={1}>
                <Input
                  placeholder={t("label.Last Name")}
                  {...register("lastName", { required: true })}
                />
                {errors.lastName && (
                  <Text color="red.500">{t("label.requiredField")}</Text>
                )}
              </Box>
            </Flex>
            <Select
              placeholder={t("label.Preferred Language")}
              {...register("language", { required: true })}
              defaultValue={
                softwareLanguages.find((lang) => lang.short === i18n.language)
                  ?.short || ""
              }
            >
              {softwareLanguages.map((lang) => (
                <option key={lang.short} value={lang.short}>
                  {lang.language}
                </option>
              ))}
            </Select>
            <Select
              placeholder={t("label.Country")}
              {...register("country", { required: true })}
            >
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </Select>
            <Text>
              {t(
                "label.Calibrite may keep me informed with emails about products and services. See our"
              )}{" "}
              <Text
                as="span"
                color="blue.500"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => openLink(privacyPolicyUrl)}
              >
                {t("miscNonHtmlText.Privacy Policy")}
              </Text>{" "}
              {t("label.for more details or to opt-out at any time.")}
            </Text>

            {!newsletter && (
              <Checkbox {...register("contactConsent")}>
                {t("label.contactConsent")}
              </Checkbox>
            )}
          </>
        )}
        <Text fontSize="sm">
          {newsletter
            ? t("label.By clicking the Sign Up button I agree to the")
            : t("label.By clicking the Create Account I agree to the")}{" "}
          <Text
            as="span"
            color="blue.500"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => openLink(termsOfUseUrl)}
          >
            {t("label.Terms of Use")}
          </Text>{" "}
          {t("label.and")}{" "}
          {!newsletter && (
            <>
              <Text
                as="span"
                color="blue.500"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => openSoftwareLicense()}
              >
                {t("label.Software License Agreement")}
              </Text>{" "}
              {t("label.and")}{" "}
            </>
          )}
          <Text
            as="span"
            color="blue.500"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => openLink(privacyPolicyUrl)}
          >
            {t("miscNonHtmlText.Privacy Policy")}
          </Text>
          .
        </Text>
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <ModalFooter justifyContent="space-between">
        <Button colorScheme="purple" onClick={onBack}>
          {t("button.Back")}
        </Button>
        {newsletter ? (
          <Button colorScheme="green" type="submit" isLoading={loading}>
            {t("button.Sign Up")}
          </Button>
        ) : (
          <Button colorScheme="green" type="submit" isLoading={loading}>
            {t("button.Create Account")}
          </Button>
        )}
      </ModalFooter>
    </form>
  );
};
