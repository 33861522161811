import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ModalFooter,
  Text,
  Flex,
  Input,
  Checkbox,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useLogin from "./useLogin";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { openLink } from "./RegistrationModel";

export const LoginStage = ({
  onSubmit,
  email,
  onBack,
  handleForgotPassword,
  apiUrl,
  newsletter,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailOptIn: false,
    },
  });
  const { login, loading, error } = useLogin(apiUrl);
  const [showPassword, setShowPassword] = useState(false);
  const [showOptIn, setShowOptIn] = useState(newsletter);

  useEffect(() => {
    const optInFlag = localStorage.getItem("emailOptIn");
    setShowOptIn(optInFlag !== "true");
  }, []);

  const onLoginSubmit = async (data) => {
    const result = await login(email, data.password, data.emailOptIn);
    if (result) {
      if (data.emailOptIn) {
        localStorage.setItem("emailOptIn", "true");
      }
      onSubmit(result.user, result.token);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const translatedError =
    error && error.toString().includes("failed")
      ? t("label.LoginFailed")
      : error;

  return (
    <form onSubmit={handleSubmit(onLoginSubmit)}>
      <Flex direction="column" gap={4}>
        <Text>
          {t("label.Email")}: {email}
        </Text>
        <InputGroup>
          <Input
            autoFocus
            type={showPassword ? "text" : "password"}
            placeholder={t("label.Password")}
            {...register("password", { required: true })}
          />
          <InputRightElement>
            <Button
              h="1.75rem"
              size="sm"
              onClick={togglePasswordVisibility}
              bg="transparent"
              _hover={{ bg: "transparent" }}
            >
              {showPassword ? (
                <ViewOffIcon color="gray.300" />
              ) : (
                <ViewIcon color="gray.300" />
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
        {errors.password && (
          <Text color="red.500">{t("label.requiredField")}</Text>
        )}
        {translatedError && <Text color="red.500">{translatedError}</Text>}
        <Text
          as="span"
          color="blue.500"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleForgotPassword}
        >
          {t("label.forgotPassword")}
        </Text>
        {showOptIn && (
          <>
            <Text>
              {t(
                "label.Calibrite may keep me informed with emails about products and services. See our"
              )}{" "}
              <Text
                as="span"
                color="blue.500"
                textDecoration="underline"
                cursor="pointer"
                onClick={() =>
                  openLink("https://calibrite.com/privacy-policy/")
                }
              >
                {t("miscNonHtmlText.Privacy Policy")}
              </Text>{" "}
              {t("label.for more details or to opt-out at any time.")}
            </Text>
            <Checkbox {...register("emailOptIn")}>
              {t("label.contactConsent")}
            </Checkbox>
          </>
        )}
      </Flex>
      <ModalFooter justifyContent="space-between">
        <Button colorScheme="purple" onClick={onBack}>
          {t("button.Back")}
        </Button>
        <Button colorScheme="green" type="submit" isLoading={loading}>
          {t("button.Login")}
        </Button>
      </ModalFooter>
    </form>
  );
};
