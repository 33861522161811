import React, { useCallback, useEffect, useState } from "react";
import { Text, Flex, Button, /* Link, */ Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useCheckEmailVerification from "./useCheckEmailVerification";

export const VerificationPendingStage = ({ email, onVerified, handleCompleteSubmit, apiUrl }) => {
  const { t } = useTranslation();
  const { checkEmailVerification, resendVerification, loading, error } =
    useCheckEmailVerification(apiUrl);

  const [showBypass, setShowBypass] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [hasResent, setHasResent] = useState(false);

  const checkVerification = useCallback(async () => {
    const isVerified = await checkEmailVerification(email);
    if (isVerified) {
      onVerified();
    }
  }, [email, checkEmailVerification, onVerified]);

  useEffect(() => {
    const interval = setInterval(checkVerification, 15 * 1000);
    return () => clearInterval(interval);
  }, [checkVerification]);

  useEffect(() => {
    // Show resend button after 1 minute
    const resendTimeout = setTimeout(() => setShowResendButton(true), 60000);
    return () => clearTimeout(resendTimeout);
  }, []);

  useEffect(() => {
    if (!hasResent) return;

    const emailSubmissionTime = localStorage.getItem("emailSubmissionTime");
    const bypassEmail = localStorage.getItem("bypassEmail");

    if (emailSubmissionTime && !bypassEmail) {
      const submissionTime = parseInt(emailSubmissionTime, 10);
      const timeElapsed = Date.now() - submissionTime;
      const fiveMinutes = 300000;
      
      if (timeElapsed >= fiveMinutes) {
        setShowBypass(true);
      } else {
        const timeout = setTimeout(
          () => setShowBypass(true),
          fiveMinutes - timeElapsed
        );
        return () => clearTimeout(timeout);
      }
    }
  }, [hasResent]);

  const openLink = (url) => {
    if (window.electronAPI) {
      window.electronAPI.externalBrowser(url);
    } else {
      window.open(url, "_blank");
    }
  };

  const handleResendVerification = () => {
    // Add API call to resend verification email here
    setShowResendButton(false);
    setHasResent(true);
    resendVerification(email);
  };

  const handleContactSupport = () => {
    localStorage.setItem("bypassEmail", email);
    localStorage.setItem(
      "bypassExpiration",
      (Date.now() + 7 * 24 * 60 * 60 * 1000).toString()
    );
    openLink("mailto:no-reply@calibrite.com");
    if (typeof handleCompleteSubmit === "function") {
      handleCompleteSubmit();
    }
  };

  return (
    <Box>
      <Flex direction="column" gap={4}>
        <Text fontWeight="bold">{t("label.Awaiting Validation")}</Text>
        <Text>
          {t("label.verificationInstructions").replace(
            "{{xxx@xxxx.xx}}",
            email
          )}
        </Text>
        <Text>
          {t(
            "label.If the email address is incorrect please exit and re-register"
          )}
        </Text>

        {!showBypass && (
          <>
            <Text>
              {t(
                "label.Please check your inbox including your spam folder for the validation link"
              )}
            </Text>
            <Text>
              {t("label.It may take up to 5 minutes for the email to arrive.")}
            </Text>
            <Text>
              {t("label.Once you receive it click the link to proceed")}
            </Text>
            {showResendButton && !hasResent && (
              <Button colorScheme="blue" onClick={handleResendVerification}>
                {t("button.Resend Verification Email")}
              </Button>
            )}
          </>
        )}

        {showBypass && (
          <>
            <Text mb={2}>
              {t( 
                "label.It looks like the verification email hasnt been received after 5 minutes"
              )}
            </Text>
            <Text>
              {t("label.Please check your inbox and spam folder again")}
            </Text>
            <Text>
              {t(
                "label.If you still havent received it please contact support for assistance"
              )}
            </Text>
            <Button colorScheme="blue" onClick={handleContactSupport}>
              {t("button.Contact Support")}
            </Button>
          </>
        )}

        {error && <Text color="red.500">{error}</Text>}
        {loading && <Text>{t("label.checkingVerification")}</Text>}
      </Flex>
    </Box>
  );
};
