import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Flex,
  Image,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { color } from "./constants/color";
import { softwareLanguages } from "./constants/data";

import BarStrip from "./BarStrip";
import LanguageSelector from "./LanguageSelector";
import calibriteLogo from "./assets/Calibrite-colour-circle_greybg.svg";
import { EmailInputStage } from "./EmailInputStage";
import { DetailsStage } from "./DetailsStage";
import { VerificationLinkStage } from "./VerificationLinkStage";

export const openLink = (url) => {
  window.open(url, "_blank");
};

const MailingListModel = ({ isOpen, onClose, apiUrl, initialStage, initialEmail, initialAuthToken, initialLang }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState(initialEmail || "");
  const [stage, setStage] = useState(initialStage || "email-input");
  const [authToken, setAuthToken] = useState(initialAuthToken || "");

  // Stage 1: Email Input
  // Stage 2: (Register Newsletter): Personal Info
  // Stage 3: (Register Newsletter): Registration Complete

  const handleEmailSubmit = useCallback(async (submittedEmail) => {
    setEmail(submittedEmail);
    localStorage.setItem("isMarketingSignup", "false");

    setStage("details");
  }, []);

  const handleDetailsSubmit = useCallback(() => {
    console.log("Registration data submitted", { email });
    setStage("registration-complete");
  }, [email]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    
    // Check for language parameter in URL or props
    const langParam = initialLang || urlParams.get("lang");
    if (langParam) {
      console.log("Language parameter found:", langParam);
      // Check if the language is supported
      const isSupported = softwareLanguages.some(lang => lang.short === langParam);
      if (isSupported) {
        console.log(`Setting language to: ${langParam}`);
        i18n.changeLanguage(langParam);
      } else {
        console.log(`Language not supported: ${langParam}. Using default.`);
      }
    } else {
      console.log("No language parameter found. Using browser default.");
    }
    
    // Handle token from props or URL
    const token = initialAuthToken || urlParams.get("token");
    if (token) {
      setAuthToken(token);

      if (window.location.href.includes("reset-password")) {
        setStage("reset-password");
      } else if (window.location.href.includes("verify")) {
        setStage("verification-link");
      }
    }
  }, [i18n, initialLang, initialAuthToken]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor={color.grey}
        color={color.lightWhite}
        outline="0.063rem solid white"
      >
        <ModalHeader>
          <Flex justifyContent={"space-between"}>
            <Image width={"40px"} mb={2} src={calibriteLogo} />
            <LanguageSelector languages={softwareLanguages} />
          </Flex>
          <BarStrip />

          <Text mt={4} fontSize="2xl" fontWeight="bold">
            {t("heading.Sign up to our mailing list")}
          </Text>
        </ModalHeader>
        <ModalBody>
          {stage === "email-input" && (
            <EmailInputStage
              onNext={handleEmailSubmit}
              apiUrl={apiUrl}
              newsletter={true}
            />
          )}
          {stage === "details" && (
            <DetailsStage
              onSubmit={handleDetailsSubmit}
              email={email}
              onBack={() => setStage("email-input")}
              apiUrl={apiUrl}
              newsletter={true}
              openSoftwareLicense={() => {}}
            />
          )}
          {stage === "registration-complete" && (
            <>
              {" "}
              <Flex direction="column" gap={4}>
                <Text>
                  {t(
                    "label.Thank you check e-mail account to validate your sign-up"
                  )}

                  <Text>{t("label.e-mail can take up to 5 minutes.")}</Text>
                </Text>
              </Flex>
              <ModalFooter>
                <Button
                  colorScheme="green"
                  onClick={() => {
                    window.close();
                  }}
                  mr={3}
                >
                  {t("button.Done")}
                </Button>
              </ModalFooter>
            </>
          )}
          {stage === "verification-link" && (
            <VerificationLinkStage token={authToken} apiUrl={apiUrl} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MailingListModel;
